html,
body {
  font-family: "Karantina", cursive;
  margin: 0;
  padding: 0;

  background: #000000;
}

@media (max-width: 600px) {
}

.App {
  text-align: center;
}

.leaflet-container.leaflet-grab {
  cursor: pointer;
}

.cursor-car .leaflet-container.leaflet-grab {
  cursor: url("assets/car64.png") 32 32, auto;
}

.cursor-truck .leaflet-container.leaflet-grab {
  cursor: url("assets/truck.png") 32 32, auto;
}

.cursor-motorbike .leaflet-container.leaflet-grab {
  cursor: url("assets/motorbike.png") 32 32, auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animated-person {
  animation-name: wiggle-person;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

.animated-person:nth-child(1) {
  animation-duration: 1s;
  animation-delay: 0.5s;
}

.animated-person:nth-child(2) {
  animation-duration: 0.5s;
  animation-delay: 0.4s;
}

.animated-person:nth-child(3) {
  animation-duration: 0.8s;
  animation-delay: 0.3s;
}

@keyframes wiggle-person {
  50% {
    top: 2px;
  }
}

@keyframes pulse {
  0% {
    transform: scaleX(1) skew(5deg, -2deg);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05) skew(3deg, -1deg);
  }

  to {
    transform: scaleX(1) skew(5deg, -2deg);
  }
}

.btn-start-horn {
  animation-name: pulse;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 5s;
}

.dialog {
  direction: rtl;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  /* width: 500px;
  max-width: calc(95vw - 40px); */
  z-index: 1500;
  text-align: center;

  width: 100%;
}

.faq-section {text-align: right;}
.lang-en .faq-section { text-align: left;}

.lang-en .dialog {
  direction: ltr;
}

.dialog-filled {
  /* background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); */

  background: #d3862f;
  filter: drop-shadow(-4px 4px 0 #030404);
  top: 50px;
  padding: 20px;
  /* box-shadow: 0 0 50px rgba(0, 0, 0); */
}

.dialog-bg {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3));
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.text-bg {
  background: #d32f2f;
  color: #ffffff;
  display: inline-block;
  padding: 5px;
}

.text-stroke {
  color: #ffffff;
  text-shadow: -5px -5px 0 #000, 0 -5px 0 #000, 5px -5px 0 #000, 5px 0 0 #000,
    5px 5px 0 #000, 0 5px 0 #000, -5px 5px 0 #000, -5px 0 0 #000;
}

.result-bg {
  padding: 60px;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 50%
  );
}

.show-buttons-delay {
  height: 0vh;
  animation-duration: 0.5s;
  animation-name: slide-in;
  animation-delay: 5s;
  overflow: hidden;
  animation-fill-mode: forwards;
}

@keyframes slide-in {
  100% {
    height: 4vh;
    overflow: visible;
  }
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em;
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #ff6600;
}
.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
  background-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
}

.resp-sharing-button--vk:hover {
  background-color: #43648c;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
  border-color: #25d366;
}

.resp-sharing-button--whatsapp:hover,
.resp-sharing-button--whatsapp:active {
  background-color: #1da851;
  border-color: #1da851;
}
